import ThemisInput from "@/components/shared/input"
import ThemisScreenItemsTable from "@/components/screen-items-table"
import ThemisReadOnly from "@/components/shared/read-only"
import { mapActions, mapGetters, mapMutations } from "vuex"
import { STATUS_KEYS, MAX_CHARACTER_LIMIT } from "@/constants"
import { PAGE_TITLE_WITHOUT_TRANSLATION } from "@/constants/page-titles"
import { SCREEN } from "@/constants/bread-crumbs/screen"

export default {
  name      : "Screen",
  components: {
    ThemisInput,
    ThemisScreenItemsTable,
    ThemisReadOnly
  },
  data: () => ({
    localScreen                : undefined,
    showAddScreenItemsDialog   : false,
    activeTab                  : null,
    isCancelAddScreenItems     : false,
    issuePropertyItemsToBeAdded: [],
    issueFieldItemsToBeAdded   : [],
    screenNameCharacterLimit   : MAX_CHARACTER_LIMIT.SCREEN_NAME,
    screenItemsAreDraggable    : false,
    draggingScreenItem         : undefined,
    dragEnterScreenItem        : undefined
  }),
  computed: {
    ...mapGetters({
      screens              : "screens/screens",
      screenItems          : "screens/screenItems",
      isLoadingScreenItems : "screens/isLoadingScreenItems",
      fieldsV2             : "fields/fieldsV2",
      issueFields          : "issueFields/issueFields",
      isScreenItemsAdded   : "screens/isScreenItemsAdded",
      isAddingScreenItems  : "screens/isAddingScreenItems",
      screenNameUpdateError: "screens/screenNameUpdateError",
      isUpdatingScreenName : "screens/isUpdatingScreenName",
      isScreenNameUpdated  : "screens/isScreenNameUpdated",
      isWorkflowsEnabled   : "configurations/isWorkflowsEnabled",
      isSortingOrderUpdated: "screenItems/isSortingOrderUpdated",
      workflows            : "workflows/workflows"
    }),
    screenId() {
      return +this.$route.params.id
    },
    screen() {
      return this.screens.find(screen => screen.id === this.screenId)
    },
    fieldsMap() {
      const fieldsMap = new Map()
      for (const field of this.fieldsV2) {
        fieldsMap.set(field.id, field)
      }
      return fieldsMap
    },
    screenWorkflowNameMap() {
      const screenWorkflowNameMap = {}
      this.workflows.forEach(workflow => {
        workflow.transitions.forEach(transition => {
          if (transition.screen) {
            if (!screenWorkflowNameMap[transition.screenId]) {
              screenWorkflowNameMap[transition.screenId] = new Set()
            }
            screenWorkflowNameMap[transition.screenId].add(workflow.name)
          }
        })
      })
      return screenWorkflowNameMap
    },
    isScreenAssociatedWithWorkflow() {
      return !!this.screenWorkflowNameMap[this.screen.id]
    },
    screenItemsForTable() {
      const screenItems = this.screenItems
        .filter(screenItem => screenItem.screenId === this.screenId)
        .map(screenItem => {
          const [screenItemField] = screenItem.fields
          return {
            id  : screenItem.id,
            name: screenItem.key === STATUS_KEYS.issueField
              ? this.fieldsMap.get(screenItemField.id)?.systemName
              : this.$t(STATUS_KEYS[screenItem.key]),
            sortingOrder: screenItem.sortingOrder
          }
        }).sort((firstScreenItem, secondScreenItem) => firstScreenItem.sortingOrder - secondScreenItem.sortingOrder)

      return screenItems
    },
    selectedScreenItem() {
      if (this.$route.params.itemId) {
        return this.screenItems.find(
          screenItem => screenItem.id === +this.$route.params.itemId
        )
      }
    },
    issueFieldIds() {
      return this.issueFields.map(issueField => issueField.fieldId)
    },
    itemsForAddFieldsToScreenTable() {
      return this.fieldsV2.filter(field => {
        const existsInScreenItems = this.screenItems.find(item => item.fields[0]?.id === field.id)
        return this.issueFieldIds.includes(field.id) && !existsInScreenItems
      })
        .map(field => {
          return {
            id       : field.id,
            name     : field.systemName,
            mandatory: false
          }
        })
    },
    itemsForAddIssuePropertiesToScreenTable() {
      return Object.keys(STATUS_KEYS)
        .filter(key => {
          return key !== STATUS_KEYS.issueField && !this.screenItems.find(item => item.key === key)
        })
        .map(key => {
          return {
            id       : key,
            name     : this.$t(STATUS_KEYS[key]),
            mandatory: false
          }
        })
    },
    getSelectedItems() {
      if (this.activeTab) {
        return this.issueFieldItemsToBeAdded.length
      } else {
        return this.issuePropertyItemsToBeAdded.length
      }
    },
    getTotalItems() {
      if (this.activeTab) {
        return this.itemsForAddFieldsToScreenTable.length
      } else {
        return this.itemsForAddIssuePropertiesToScreenTable.length
      }
    },
    isScreenNameDuplicate() {
      return this.screenNameUpdateError?.type === "duplicate"
    },
    isScreenNameChanged() {
      return this.screen?.name !== this.localScreen?.name
    }
  },
  methods: {
    ...mapActions({
      addIssueFields  : "issueFields/addIssueFields",
      notify          : "shared/notify",
      updateIssueField: "issueFields/updateIssueField",
      addScreenItems  : "screens/addScreenItems",
      updateScreen    : "screens/updateScreen",
      updateScreenItem: "screenItems/updateScreenItem"
    }),
    ...mapMutations({
      resetScreenUpdateError: "screens/resetScreenUpdateError",
      setBreadcrumbs        : "shared/setBreadcrumbs",
      setPageTitle          : "shared/setPageTitle"
    }),
    navigateToScreenItemConfiguration(item) {
      if (+this.$route.params?.itemId !== item.id) {
        this.$router.push({
          name  : "screen-item-side-panel", params: {
            id    : this.screenId,
            itemId: item.id
          }
        })
      }
    },
    getClassForScreenItemRow(item) {
      let classForScreenItemRow = ""
      if (item.id === this.selectedScreenItem?.id) {
        classForScreenItemRow = "blue lighten-5"
      } else if (item.id === this.dragEnterScreenItem?.id &&
        item.id !== this.draggingScreenItem?.id) {
        if (this.dragEnterScreenItem?.sortingOrder < this.draggingScreenItem?.sortingOrder) {
          classForScreenItemRow += "drop-row-down"
        } else {
          classForScreenItemRow += "drop-row-up"
        }
      }
      return classForScreenItemRow
    },
    async cancelAddFieldsToIssueHandler() {
      this.showAddScreenItemsDialog    = false
      this.isCancelAddScreenItems      = true
      this.issuePropertyItemsToBeAdded = []
      this.issueFieldItemsToBeAdded    = []
      this.activeTab                   = null
      await this.$nextTick()
      this.isCancelAddScreenItems = false
    },
    issuePropertiesToBeAdded(items) {
      this.issuePropertyItemsToBeAdded = items
    },
    issueFieldsToBeAdded(items) {
      this.issueFieldItemsToBeAdded = items
    },
    handleAddScreenItems() {
      const screenItems = []
      if (this.issuePropertyItemsToBeAdded.length) {
        screenItems.push(...this.issuePropertyItemsToBeAdded
          .map(item => ({
            key      : item.id,
            mandatory: item.mandatory
          })))
      }
      if (this.issueFieldItemsToBeAdded.length) {
        screenItems.push(...this.issueFieldItemsToBeAdded
          .map(item => ({
            key      : "issueField",
            mandatory: item.mandatory,
            field    : { id: item.id }
          })))
      }
      this.addScreenItems({ screenId: this.screen.id, screenItems })
    },
    handleScreenNameOnBlurEvent(onBlur) {
      onBlur()
      this.verifyAndUpdateScreenName()
    },
    verifyAndUpdateScreenName() {
      if (this.isScreenNameChanged) {
        if (this.localScreen.name &&
          this.localScreen.name.length <= this.screenNameCharacterLimit) {
          this.updateScreen({
            id  : this.localScreen.id,
            name: this.localScreen.name
          })
        }
      }
    },
    handleScreenNameInputOnEnter() {
      this.$refs.input_screen_name.blur()
    },
    screenItemDragStart(screenItem) {
      this.draggingScreenItem  = screenItem
      this.dragEnterScreenItem = this.draggingScreenItem
    },
    screenItemDragEnter(screenItem) {
      this.dragEnterScreenItem = screenItem
    },
    onScreenItemDragOver(event) {
      event.preventDefault()
    },
    onScreenItemDragEnd() {
      if (this.draggingScreenItem.id !== this.dragEnterScreenItem.id) {
        const isScreenItemDraggedUp =
          !!(this.draggingScreenItem.sortingOrder - this.dragEnterScreenItem.sortingOrder)

        const sortingOrder = isScreenItemDraggedUp ?
          this.dragEnterScreenItem.sortingOrder : this.dragEnterScreenItem.sortingOrder - 1

        this.updateScreenItem({
          id: this.draggingScreenItem.id,
          sortingOrder
        })
      }
    }
  },
  watch: {
    screen: {
      immediate: true,
      handler  : function(newValue) {
        if (!this.localScreen) {
          this.localScreen = { ...newValue }
        }
      }
    },
    isScreenItemsAdded: {
      handler: function(value) {
        if (value) {
          this.notify({
            type: "success",
            text: "1521"
          })
          this.showAddScreenItemsDialog = false
          this.cancelAddFieldsToIssueHandler()
        }
      }
    },
    showAddScreenItemsDialog: {
      handler: function(value) {
        if (!value) {
          this.fieldsToBeAdded = []
        }
      }
    },
    "localScreen.name": {
      immediate: false,
      handler  : function() {
        if (this.screenNameUpdateError) {
          this.resetScreenUpdateError(["name"])
        }
      }
    },
    isScreenNameUpdated: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.setBreadcrumbs(
            SCREEN({ params: { id: this.localScreen.id } }, this.localScreen.name
            )
          )
          this.setPageTitle(PAGE_TITLE_WITHOUT_TRANSLATION(this.localScreen.name))
          this.notify({
            type: "success",
            text: "1521"
          })
        }
      }
    },
    isSortingOrderUpdated: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.notify({
            type: "success",
            text: "1521"
          })
          this.draggingScreenItem  = undefined
          this.dragEnterScreenItem = undefined
        }
      }
    }
  }
}