import { getHeadersForTable } from "@/utils/table"
import { TABLE_NAMES } from "@/constants"

export default {
  name : "ScreenItemsTable",
  props: {
    pItemsForTable         : Array,
    pIsCancelAddScreenItems: Boolean
  },
  data: () => ({
    itemsToBeAdded: []
  }),
  computed: {
    headersForAddItemsTable() {
      return getHeadersForTable(TABLE_NAMES.SCREEN_ADD_ITEMS, this.$t.bind(this))
    },
    calculateHeightForTable() {
      const dataRowHeight      = 48
      const maxAvailableHeight = window.innerHeight - 430
      const maxDataRows        = Math.floor((maxAvailableHeight / dataRowHeight) - 1)
      let heightOfTable        = dataRowHeight // initialize with height for header

      if (this.pItemsForTable.length > maxDataRows) {
        heightOfTable += maxDataRows * dataRowHeight
      } else if (!this.pItemsForTable.length) {
        heightOfTable += dataRowHeight // one row for "no data available"
      } else {
        heightOfTable += this.pItemsForTable.length * dataRowHeight
      }
      return heightOfTable
    }
  },
  watch: {
    pIsCancelAddScreenItems: {
      handler: function(newValue) {
        if (newValue) {
          this.itemsToBeAdded = []
        }
      }
    },
    itemsToBeAdded: {
      handler: function(newValue) {
        if (newValue) {
          this.$emit("itemsToBeAdded", newValue)
        }
      }
    }
  }
}